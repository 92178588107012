<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="call-me-modal">
      <v-card-title>
        <v-btn fab dark color="info" x-small text @click="$emit('closeModal')">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="dialog">
        <div class="call-me-modal__body">
          <v-form
            class="form"
            @submit.prevent="submit"
            v-model="valid"
            ref="form"
          >
            <div class="form__body">
              <v-text-field
                v-model="formData.phone"
                outlined
                label="Numéro de téléphone"
                type="text"
                persistent-placeholder
                dense
                placeholder="Numéro de téléphone"
                :rules="[validations.required]"
              ></v-text-field>
              <v-textarea
                v-model="formData.message"
                outlined
                label="Vos commentaires"
                type="text"
                persistent-placeholder
                dense
                placeholder=""
                :rules="[validations.required]"
              ></v-textarea>
              <div class="text-center">
                <v-btn
                  color="info"
                  depressed
                  type="submit"
                  class="form__submit"
                  :loading="loading"
                >
                  Appelez-moi
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations/index";
import { MessageService } from "@/services/messages.service.js";

export default {
  name: "RoomModal",
  props: {
    show: {
      type: Boolean,
    },
  },
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      formData: {
        phone: this.$store.getters["authModule/getUserProfile"]?.phone,
        message: "",
      },
      valid: true,
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  watch: {
    "$store.state.authModule.userProfile": {
      deep: true,
      handler(profile) {
        this.$set(this.formData, "phone", profile.phone);
      },
    },
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.form.resetValidation();
          });
        }
      },
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      this.$refs.form.validate()
      MessageService.createMessage({
        phone: this.formData.phone,
        message: this.formData.message,
      })
        .then(({ data }) => {
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.base")
          );
          this.formData = {
            phone: this.$store.getters["authModule/getUserProfile"]?.phone,
            message: "",
          }
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => {
          this.$emit("closeModal");
          this.loading = false;
        });
    },
  },
};
</script>

<style src="./AppCallMeModal.scss" lang="scss" scoped></style>